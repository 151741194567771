import styled, { keyframes, css } from 'styled-components';
import { Container, GradientCircle, Paragraph, SectionHeader } from '@swordhealth/ui-corporate';

const fadeIn = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
`;

export const StyledContainer = styled(Container)`
  position: relative;
  gap: 0;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: 32px;
  }
`;

export const StyledSectionHeader = styled(SectionHeader)`
  padding: 32px 0 24px;
  gap: 24px;
  opacity: 0;
  z-index: 10;

  ${(props) =>
    props.notAnimate &&
    css`
      opacity: 1;
    `};
  ${(props) =>
    props.animate &&
    css`
      animation: ${fadeIn} 2000ms ease-in-out forwards;
    `};

  h1 {
    font-size: 80px;
    font-weight: ${(props) => props.theme.font.weight.regular};
    letter-spacing: -1.5px;
    line-height: 80px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      max-width: 628px;
      font-size: 48px;
      letter-spacing: -1px;
      line-height: 56px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.min_sm}px) {
      max-width: 312px;
      font-size: 32px;
      letter-spacing: -0.5px;
      line-height: 40px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 8px 0 16px;
    gap: 16px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 0 0 16px;
  }
`;

export const StyledParagraph = styled(Paragraph)`
  color: #747986;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  opacity: 0;
  z-index: 1;

  ${(props) =>
    props.animate &&
    css`
      animation: ${fadeIn} 2000ms ease-in-out forwards;
    `};

  ${(props) =>
    props.notAnimate &&
    css`
      opacity: 1;
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  height: 100%;
  padding-top: 64px;
  z-index: 1;

  img {
    object-fit: contain;
    width: 100%;
    opacity: 0;

    @keyframes fadeInUp {
      from {
        opacity: 0;
        transform: translateY(20%);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    ${(props) =>
      props.animate &&
      css`
        animation: fadeInUp 1500ms ease-in-out forwards;
      `};

    ${(props) =>
      props.notAnimate &&
      css`
        opacity: 1;
      `};

    ${(props) =>
      props.$isReferral &&
      css`
        height: auto;
      `};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.min_sm}px) {
    padding-top: 32px;
    max-width: 100%;
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
`;

export const GradientBG = styled(GradientCircle)`
  position: absolute;
  max-width: 800px;
  max-height: 350px;
  left: 50%;
  transform: translateX(-50%) scaleX(-1);
  z-index: -1;
  opacity: 0.9;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-height: 300px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.min_sm}px) {
    max-width: 400px;
    max-height: 140px;
  }
`;

export const LogosWrapper = styled.div`
  text-align: center;
  z-index: 10;
  width: 100%;
  padding-top: 44px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: 0;
  }

  > div {
    display: inline-flex;
  }
`;

export const SimpleHeroGradiant = styled.div`
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 454px;
  width: 100vw;
  min-width: 1645px;
  background: url('/assets/hero-thematic.svg') no-repeat bottom center;
  background-size: cover;
  z-index: 0;
`;

export const FixedWrapper = styled.div`
  width: 100%;
  height: 66px;
  background-color: ${(props) => props.theme.colors.neutral.default};
  text-align: center;
  padding: 10px 0;
  position: fixed;
  top: 0;
  z-index: 1;
  display: none;
  box-shadow: rgba(233, 220, 211, 0.4) 0 2px 10px 0;

  ${(props) =>
    props.$visible &&
    css`
      display: block;
    `};
`;

export const EnrollButtonWrapper = styled.div`
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100%;
    text-align: center;
  }
`;
